import React from 'react'
import Layout from '../components/Layout'

const NotFound = () => {
  return (
    <Layout>
      <div>
        <h2>Erreur 404</h2>
      </div>
    </Layout>
  );
}
 
export default NotFound